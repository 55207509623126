import { useState, useEffect } from "react";
import './ChangedRecords.css';
import { CofA, putCosmosDBRecord, rejectMakeChanges } from "./fetchData";
import { useMsal } from '@azure/msal-react';
import { BsToggleOff, BsToggleOn, BsArrowBarDown, BsArrowBarUp } from "react-icons/bs";


export default function AtomicRecord({id, item, setNewRecord, refresh, db, setCanMakeChanges, idLocked, setIdLocked}) {
    const [ oldRecord, setOldRecord ] = useState(item.initialRecord);
    const [ newRecord, setNewRecord2 ] = useState(item.proposedChange);
    const [ equalRecord, setEqualRecord ] = useState(new Object());
    const [ largeScreen, setLargeScreen ] = useState(window.matchMedia('(min-device-width: 769px)').matches);

    useEffect(() => {
        setLargeScreen(window.matchMedia('(min-device-width: 769px)').matches);
    });

    const dateSet = ['year', 'month', 'day', 'fx_year', 'fx_month', 'fx_day', 'year_start', 'month_start', 'day_start', 'schedule', 'asset account', 'reverse VAT', 'VAT included', 'delayed', 'amortization'];

    useEffect(() => {
        const theKeys = new Set([...Object.keys(newRecord), ...Object.keys(oldRecord), ...dateSet]);
      
        theKeys.forEach(key => {
            if (oldRecord.hasOwnProperty(key) && newRecord.hasOwnProperty(key)) {
                if (key==='documents') setEqualRecord(equalRecord => ({...equalRecord, [key]: compareArrays(newRecord.documents, oldRecord.documents)}));
                else setEqualRecord(equalRecord => ({...equalRecord, [key]: (newRecord[key] === oldRecord[key])}));
            }
            else if (!(oldRecord.hasOwnProperty(key) || newRecord.hasOwnProperty(key))) {
                setEqualRecord(equalRecord => ({...equalRecord, [key]: true}));
            }
            else setEqualRecord(equalRecord => ({...equalRecord, [key]: false}));
        });
        
        if (oldRecord.fx_day && oldRecord.fx_month && oldRecord.fx_year) setOldRecord(oldRecord => ({...oldRecord, fx_date: true}));
        else setOldRecord(oldRecord => ({...oldRecord, fx_date: false}));
        if (newRecord.fx_day && newRecord.fx_month && newRecord.fx_year) setNewRecord2(newRecord => ({...newRecord, fx_date: true}));
        else setNewRecord2(newRecord => ({...newRecord, fx_date: false}));
        
        if (oldRecord.day_start && oldRecord.month_start && oldRecord.year_start) setOldRecord(oldRecord => ({...oldRecord, date_start: true}));
        else setOldRecord(oldRecord => ({...oldRecord, date_start: false}));
        if (newRecord.day_start && newRecord.month_start && newRecord.year_start) setNewRecord2(newRecord => ({...newRecord, date_start: true}));
        else setNewRecord2(newRecord => ({...newRecord, date_start: false}));
    }, []);

    useEffect(()=>{
        if (equalRecord.day && equalRecord.month && equalRecord.year) setEqualRecord(equalRecord => ({...equalRecord, date: true}));
        else setEqualRecord(equalRecord => ({...equalRecord, date: false}));
    }, [equalRecord.day, equalRecord.month, equalRecord.year]);

    useEffect(() => {
        if (equalRecord.day_start && equalRecord.month_start && equalRecord.year_start) setEqualRecord(equalRecord => ({...equalRecord, date_start: true}));
        else setEqualRecord(equalRecord => ({...equalRecord, date_start: false}));
    }, [equalRecord.day_start, equalRecord.month_start, equalRecord.year_start])

    useEffect(() => {
        if (equalRecord.fx_day && equalRecord.fx_month && equalRecord.fx_year) setEqualRecord(equalRecord => ({...equalRecord, fx_date: true}));
        else setEqualRecord(equalRecord => ({...equalRecord, fx_date: false}));
    }, [equalRecord.fx_day, equalRecord.fx_month, equalRecord.fx_year]);

    const { instance } = useMsal();

    function rejectChange() {
        rejectMakeChanges({instance: instance, Location: idLocked});
    }

    function compareArrays(arrA, arrB) {
        if (arrA.length !== arrB.length) return false;
        const sortedArrA = arrA.slice().sort();
        const sortedArrB = arrB.slice().sort();
        return sortedArrA.every((value, index) => value === sortedArrB[index]);
    }

    function emptyQueue(argToIgnore) {
        ;
    }

    function saveToDataBase() {
        putCosmosDBRecord({ setCanMakeChanges: setCanMakeChanges, instance: instance, record: newRecord, setInitialRecord: emptyQueue, method: 'PUT' });
    }

    if (true || (newRecord && largeScreen)) return (
        <div className="changed-record">
            <div style={{width: "100%"}}>
                <div className="changed-record-content">
                    <h4 className="span-three-columns">Record id&nbsp;&nbsp;&nbsp;{oldRecord.id}</h4>
                    <span></span><h4>Current</h4><h4>Suggested</h4>
                    <span className={`${equalRecord.date ? 'gray-out' : ''} record-key`}>Date</span>
                        <span className={`${equalRecord.date ? 'gray-out' : ''} ca`}>{String(oldRecord.day).padStart(2, '0')}.{String(oldRecord.month).padStart(2, '0')}.{oldRecord.year}</span>
                            <span className={`${equalRecord.date ? 'gray-out' : ''} ca`}>{!equalRecord.date ? <>{String(newRecord.day).padStart(2, '0')}.{String(newRecord.month).padStart(2, '0')}.{newRecord.year}</> : ''}</span>
                    <span className={`${equalRecord.debit ? 'gray-out' : ''} record-key`}>Dr</span>
                        <span className={`${equalRecord.debit ? 'gray-out' : ''} ca tooltip-cell`}>
                            <span>{oldRecord.debit}</span>
                            <span className="tooltip-text tooltip-debit-old">{CofA[oldRecord.debit]}</span>
                        </span>
                            { !equalRecord.debit ? 
                            <span className={`${equalRecord.debit ? 'gray-out' : ''} ca tooltip-cell`}>
                                <span>{newRecord.debit}</span>
                                <span className="tooltip-text tooltip-debit-new">{CofA[newRecord.debit]}</span>
                            </span> : <span></span> }
                    <span className={`${equalRecord.credit ? 'gray-out' : ''} record-key`}>Cr</span>
                        <span className={`${equalRecord.credit ? 'gray-out' : ''} ca tooltip-cell`}>
                            <span>{oldRecord.credit}</span>
                            <span className="tooltip-text tooltip-debit-old">{CofA[oldRecord.credit]}</span>
                        </span>
                            { !equalRecord.credit ? 
                            <span className={`${equalRecord.credit ? 'gray-out' : ''} ca tooltip-cell`}>
                                <span>{newRecord.credit}</span>
                                <span className="tooltip-text tooltip-debit-new">{CofA[newRecord.credit]}</span>
                            </span> : <span></span> }
                    { (oldRecord.amortization || newRecord.amortization) && <><span className={`${equalRecord['asset account'] ? 'gray-out' : ''} record-key`}>Asset account</span>
                        <span className={`${equalRecord['asset account'] ? 'gray-out' : ''} ca tooltip-cell`}>
                            <span>{oldRecord['asset account']}</span>
                            <span className="tooltip-text tooltip-asset-account-old">{CofA[oldRecord['asset account']]}</span>
                        </span>
                            { !equalRecord['asset account'] ? 
                            <span className={`${equalRecord['asset account'] ? 'gray-out' : ''} ca tooltip-cell`}>
                                <span>{newRecord['asset account']}</span>
                                <span className="tooltip-text tooltip-debit-new">{CofA[newRecord['asset account']]}</span>
                            </span> : <span></span> }
                        </>}

                    <span className={`${equalRecord.amount && equalRecord.currency ? 'gray-out' : ''} record-key`}>Amount</span>
                        <span className={`${equalRecord.amount && equalRecord.currency ? 'gray-out' : ''} ca`}>{oldRecord.amount/100.0} {oldRecord.currency ? oldRecord.currency : 'GEL'}</span>
                            <span className={`${equalRecord.amount && equalRecord.currency ? 'gray-out' : ''} ca`}>{!(equalRecord.amount && equalRecord.currency) ? `${newRecord.amount/100.0} ${newRecord.currency ? newRecord.currency : 'GEL'} `: '  '}</span>

                    <span className={`${equalRecord['VAT included'] ? 'gray-out' : ''} record-key`}>VAT included</span>
                        <span className={`${equalRecord['VAT included'] ? 'gray-out' : ''} ca`}>{oldRecord['VAT included'] === undefined ? <em>undefined</em> : <em>{oldRecord['VAT included'].toString()}</em> }</span>
                            <span className={`${equalRecord['VAT included'] ? 'gray-out' : ''} ca`}>{!equalRecord['VAT included'] ? (newRecord['VAT included'] === undefined ? <em>undefined</em> : <em>{newRecord['VAT included'].toString()}</em> ) : ''}</span>

                    <span className={`${equalRecord['reverse VAT'] ? 'gray-out' : ''} record-key`}>Reverse VAT</span>
                        <span className={`${equalRecord['reverse VAT'] ? 'gray-out' : ''} ca`}>{oldRecord['reverse VAT']
                             === undefined ? <em>undefined</em> : <em>{oldRecord['reverse VAT'].toString()}</em>}</span>
                            <span className={`${equalRecord['reverse VAT'] ? 'gray-out' : ''} ca`}>{!equalRecord['reverse VAT'] ? (newRecord['reverse VAT']
                             === undefined ? <em>undefined</em> : <em>{newRecord['reverse VAT'].toString()}</em>) : ''}</span>

                    {(oldRecord.currency !== 'GEL' || newRecord.currency !== 'GEL') && <>
                        <span className={`${equalRecord.fx ? 'gray-out' : ''} record-key`}>Exchange rate</span>
                            { oldRecord.currency !== 'GEL'? <span className={`${equalRecord.fx ? 'gray-out' : ''} ca`}>{oldRecord.fx/10000.0}</span> : <span></span> }
                                { (newRecord.currency !== 'GEL')? <span className={`${equalRecord.fx ? 'gray-out' : ''} ca`}>{newRecord.fx/10000.0}</span> : <span></span> }
                        <span className={`${equalRecord.fx_date ? 'gray-out' : ''} record-key`}>FX rate date</span>
                            {oldRecord.fx_date ? <span className={`${equalRecord.fx_date ? 'gray-out' : ''} ca`}>{String(oldRecord.fx_day).padStart(2, '0')}.{String(oldRecord.fx_month).padStart(2, '0')}.{oldRecord.fx_year}</span> : <span></span>}
                                {newRecord.fx_date ? <span className={`${equalRecord.fx_date ? 'gray-out' : ''} ca`}>{(newRecord.fx_date && !equalRecord.fx_date) || (newRecord.fx_date && oldRecord.currency === 'GEL' && newRecord.currency !== 'GEL') ? <>{String(newRecord.fx_day).padStart(2, '0')}.{String(newRecord.fx_month).padStart(2, '0')}.{newRecord.fx_year}</> : ''}</span> : <span>Ok</span>}
                    </>}
                    <span className={`${equalRecord.amortization ? 'gray-out' : ''} record-key`}>Amortization</span>
                        <span className={`${equalRecord.amortization ? 'gray-out' : ''} ca`}>{oldRecord.amortization === undefined ? <em>undefined</em> : <em>{oldRecord.amortization.toString()}</em>}</span>
                            <span className={`${equalRecord.amortization ? 'gray-out' : ''} ca`}>{!equalRecord.amortization ? (newRecord.amortization === undefined ? <em>undefined</em> : <em>{newRecord.amortization.toString()}</em>) : ''}</span>
                    
                    { (oldRecord.amortization || newRecord.amortization) && <>
                        <span className={`${equalRecord.schedule ? 'gray-out' : ''} record-key`}>Months of amortization</span>
                        <span className={`${equalRecord.schedule ? 'gray-out' : ''} ca`}>{oldRecord.schedule}</span>
                            <span className={`${equalRecord.schedule ? 'gray-out' : ''} ca`}>{!equalRecord.schedule || (newRecord.amortization && !oldRecord.amortization) ? newRecord.schedule : ''}</span>
                        </>}
                    
                    <span className={`${equalRecord.delayed ? 'gray-out' : ''} record-key`}>Delayed</span>
                        <span className={`${equalRecord.delayed ? 'gray-out' : ''} ca`}>
                        {oldRecord.delayed === undefined ? <em>undefined</em> : <em>{oldRecord.delayed.toString()}</em>}</span>
                            <span className={`${equalRecord.delayed ? 'gray-out' : ''} ca`}>{!equalRecord.delayed ? (newRecord.delayed === undefined ? <em>undefined</em> : <em>{newRecord.delayed.toString()}</em>) : ''}</span>


                    { (oldRecord.delayed || newRecord.delayed) && <><span className={`${equalRecord.date_start ? 'gray-out' : ''} record-key`}>Delayed date</span>
                        <span className={`${equalRecord.date_start ? 'gray-out' : ''} ca`}>{oldRecord.delayed ? `${String(oldRecord.day_start).padStart(2, '0')}.${String(oldRecord.month_start).padStart(2, '0')}.${oldRecord.year_start}` : ''}</span>
                            <span className={`${equalRecord.date_start ? 'gray-out' : ''} ca`}>{(newRecord.delayed || (newRecord.day_start && !oldRecord.day_start) && !equalRecord.date_start) ? `${String(newRecord.day_start).padStart(2, '0')}.${String(newRecord.month_start).padStart(2, '0')}.${newRecord.year_start}` : ''}</span>
                        </>}
                    
                    <span className={`${equalRecord.description ? 'gray-out' : ''} record-key`}>Description</span><span className={`${equalRecord.description ? 'gray-out' : ''} span-two-columns`}></span>
                        <span className={`${equalRecord.description ? 'gray-out' : ''} textual span-three-columns`}>{oldRecord.description}</span>
                            <span className="textual span-three-columns">{!equalRecord.description ? <div className="border-above ">{newRecord.description}</div> : ''}</span>

                    <span className={`${equalRecord.note ? 'gray-out' : ''} record-key`}>Note</span><span className="span-two-columns"></span>
                        <span className={`${equalRecord.note ? 'gray-out' : ''} textual span-three-columns`}>{oldRecord.note}</span>
                            <span className={`${equalRecord.note ? 'gray-out' : ''} textual span-three-columns`}>{!equalRecord.note ? <div className="border-above ">{newRecord.note}</div> : ''}</span>

                    <span className={`${equalRecord.documents ? 'gray-out' : ''} record-key`}>Documents</span>
                        <span className={equalRecord.documents ? 'gray-out' : ''}>{ oldRecord.documents.join(', ') }</span>
                            <span className={equalRecord.documents ? 'gray-out' : ''}>{ !compareArrays(oldRecord.documents, newRecord.documents) ? newRecord.documents.join(', ') : '' }</span>
                </div>
                <div className="changed-record-button-container">
                    <button className="atomic-record-button" onClick={saveToDataBase}>Accept</button>
                    <button className="atomic-record-button" onClick={rejectChange}>Reject</button>
                </div>
           </div>
        </div>
    ); /*
    else if (record && !hidden) return (<div
        className={`atomic-record-small ${ isNewRecord ? "new-atomic-record-small" : ""}`}
        onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}  >
                <div onClick={() => setHidden(true)}><BsArrowBarUp/></div>
                {isNewRecord && <h4 className="new-record-title">New record</h4>}
                <form id={trigger}>
                    <label className="small-left">Record id</label>
                                    <div className="small-right">{record.id}</div>
                    <label className="small-left"> Date</label>
                        <div className="small-right-div">
                            <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="date" onChange={handleDateChange} onBlur={handleDateDefocus} value={`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`} />
                        </div>
                        
                    <label className="small-left" htmlFor="debit">Dr</label>
                    <span className="tooltip-cell small-right-div">
                        <input type="text" className="debit small" id="debit" name="debit" value={record.debit} onChange={handleDebitChange} onBlur={handleDebitDefocus} onKeyDown={handleDebitEnter}/>
                        <span className="tooltip-text tooltip-debit-small">{CofA[record.debit]}</span>
                    </span>
                    <label className="small-left" htmlFor="credit">Cr</label>
                    <span className="tooltip-cell small-right-div">
                        <input type="text" className="credit small" id="credit" name="credit" value={record.credit} onChange={handleCreditChange} onBlur={handleCreditDefocus} onKeyDown={handleCreditEnter}/>
                        <span className="tooltip-text tooltip-credit-small">{CofA[record.credit]}</span>
                    </span>
                    <label className="small-left" htmlFor="amount"> Amount</label>
                        <div className="small-right-div">
                            <input type="number" min="0.01" step="0.01" className="amount small" id="amount" name="amount" value={record.amount/100.0} onChange={handleAmountChange} onBlur={handleAmountDefocus} onKeyDown={handleAmountEnter}/>
                            <input type="text" className="currency small" name="currency" value={record.currency} onChange={handleCurrencyChange} onBlur={handleCurrencyDefocus} onKeyDown={handleCurrencyEnter}/>
                        </div>
                       
                    
                    {(record.currency !== 'GEL') && <><label className="small-left" htmlFor="fx">{record.currency}/GEL rate</label>
                        <div className="small-right-div">
                            <input type="number" min="0.0001" step="0.0001" className="fx small" id="fx" name="fx" value={record.fx/10000.0} onChange={handleFxChange} onBlur={handleFxDefocus} onKeyDown={handleFxEnter}/>
                        </div>
                    
                    <label className="small-left"> FX rate date</label>
                        <div className="small-right-div">
                            {record.fx_year ?
                            <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="fx_date" onChange={handleFxDateChange} onBlur={handleFxDateDefocus} value={`${record.fx_year}-${String(record.fx_month).padStart(2, '0')}-${String(record.fx_day).padStart(2, '0')}`} />
                            : <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="fx_date" onChange={handleFxDateChange} onBlur={handleFxDateDefocus} value={`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`} />}
                        </div>
                    </>}
                    <label className="small-left">VAT included&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <div className={record['reverse VAT'] ? "switch-non-active": "switch-active"} onClick={() => {
                            if (!record['reverse VAT']) {
                                setRecord({...record, 'VAT included': !record['VAT included']}); 
                                setRecordLastValid({...recordLastValid, 'VAT included': !record['VAT included']});
                            }
                        }}>{record['VAT included'] ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    
                    <label className="small-left"> Reverse VAT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <div className={record['VAT included'] ? "switch-non-active": "switch-active"} onClick={() => {
                            if (!record['VAT included']) {
                                setRecord({...record, 'reverse VAT': !record['reverse VAT']}); 
                                setRecordLastValid({...recordLastValid, 'reverse VAT': !record['reverse VAT']});
                            }
                        }}>{record['reverse VAT'] ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    
                    <label className="small-left"> Amortization&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <div className="switch-active" onClick={switchAmortizatioSmall}>{record.amortization ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    
                    { record.amortization && <><label className="small-right-div" htmlFor="asset-account">asset account</label>
                    <span className="tooltip-cell small-left">
                        <input type="text" className="asset-account small" name="asset-account" placeholder="1710" value={record['asset account']} onChange={handleAssetAccountChange} onBlur={handleAssetAccountDefocus} onKeyDown={handleAssetAccountEnter}/>
                        <span className="tooltip-text tooltip-asset-account-small">{CofA[record['asset account']]}</span>
                    </span>
                    <div className="small-right-div">
                        <input type="number" min="1" step="1" max="60" className="schedule small" name="schedule" placeholder="12" value={record.schedule} onChange={changeSchedule} onBlur={blurSchedule} onKeyDown={keyDownSchedule}/>
                    </div>
                    <label className="small-left">&nbsp;months</label></> }
                    <label> Delayed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <div className="switch-active" onClick={switchDelayedSmall}>{record.delayed ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    { record.delayed && <>
                        <div className="small-right-div">
                            <label>until&nbsp;</label>
                        </div>
                        <div className="small-left-div">
                            <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="date_start" onChange={handleStartDateChange} onBlur={handleStartDateDefocus} value={`${record.year_start}-${String(record.month_start).padStart(2, '0')}-${String(record.day_start).padStart(2, '0')}`} />
                        </div>
                    </>}
                    <div className="span-two-columns">
                        <label className="small-left description small"> Description<br/>
                        <textarea className="description small" rows="3" value={record.description != null ? record.description : ''} onChange={changeDescription}/></label>
                    </div>
                    <div className="span-two-columns">
                        <label className="small-left note small"> Note&nbsp;
                        <textarea className="note small" rows="3" value={record.note != null ? record.note : '' } onChange={changeNote}/></label>
                    </div>
                    <div className="span-two-columns" style={{display: 'flex', flexWrap: 'wrap'}}>
                        <label className="small-left" htmlFor="documents">Documents&nbsp;</label>
                        { recordLastValid.documents.map((doc, i) => (
                            <Document i={i} docProp={doc} record={record} setRecord={setRecord} recordLastValid={recordLastValid} setRecordLastValid={setRecordLastValid} initialRecord={initialRecord} trigger={trigger}/>)) }
                            <NewDocument record={record} setRecord={setRecord} recordLastValid={recordLastValid} setRecordLastValid={setRecordLastValid} initialRecord={initialRecord} trigger={trigger}/>
                    </div>
                </form>
                <div className="atomic-record-button-container">
                    <button className="atomic-record-button" onClick={resetButtonClick} disabled={areRecordsEqual(record, initialRecord)}>Reset</button>
                    <button className="atomic-record-button" onClick={isNewRecord ? addToDataBase : saveToDataBase} disabled={isNewRecord ? false : areRecordsEqual(record, initialRecord)}>Save</button>
                    {!isNewRecord && <button className="atomic-record-button" onClick={() => {
                        setHidden(true);
                        setNewRecord({...recordLastValid, documents: recordLastValid.documents.slice() });
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                    }} >New</button>}
                    {isNewRecord && <button className="atomic-record-button" onClick={() => setNewRecord(null)} >Cancel</button>}
                    {!isNewRecord && <button className="atomic-record-button" onClick={() => setDeleteRequested({ recordLastValid: recordLastValid, setInitialRecord: setInitialRecord })} >Delete</button>}
                </div>
        </div>);
        else if (record && hidden) return (<div
            className={`atomic-record-small-hidden ${ isNewRecord ? "new-atomic-record-small-hidden" : ""}`}
            onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}  >
                    {isNewRecord && <h4 className="new-record-title">Unsaved record</h4>}
                    <p className="date-hidden">{String(record.day).padStart(2, '0')}.{String(record.month).padStart(2, '0')}.{record.year}</p>
                    <p className="debit-credit-hidden">
                        Dr&nbsp;&nbsp;
                        <span className="tooltip-cell">
                        {record.debit}<span className="tooltip-text tooltip-debit-small-hidden">{CofA[record.debit]}</span>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cr&nbsp;&nbsp;
                        <span className="tooltip-cell">
                            {record.credit}<span className="tooltip-text tooltip-credit-small-hidden">{CofA[record.credit]}</span>
                        </span>
                    </p>
                    <p className="amount-hidden">{record.amount/100.0} {record.currency}</p>                   
                    <p className="descritopn-hidden">{record.description != null ? record.description : ''}</p>
                    <div onClick={() => setHidden(false)}><BsArrowBarDown/></div>
            </div>); */
}