import { useState, useEffect } from "react";
import './AtomicRecords.css';
import { CofA, putCosmosDBRecord, requestMakeChanges } from "./fetchData";
import { useMsal } from '@azure/msal-react';
import { BsToggleOff, BsToggleOn, BsArrowBarDown, BsArrowBarUp } from "react-icons/bs";


function Document({i, docProp, record, setRecord, recordLastValid, setRecordLastValid, initialRecord, trigger}) {
    if (record) return (
        <input className="doc-input" value={record.documents[i]} onChange={str => {
            if ((/^[RBC][1-9]\d{0,1}$/).test(str.target.value)) {
                const tempLastValidList = recordLastValid.documents.slice();
                tempLastValidList[i] = str.target.value;
                setRecordLastValid({ ...recordLastValid, documents: tempLastValidList });
            }
            const tempList = record.documents.slice();
            tempList[i] = str.target.value;
            setRecord({ ...record, documents: tempList });
        }} onBlur={() => {
            const tempList = record.documents.slice();
            const tempListLastValid = recordLastValid.documents.slice();
            if (!record.documents[i]) {
                tempList.splice(i, 1);
                tempListLastValid.splice(i, 1);
                setRecord({...record, documents: tempList});
                setRecordLastValid({...recordLastValid, documents: tempListLastValid});
            } else {
                tempList[i] = recordLastValid.documents[i];
                const uniqueTempDocs = tempList.filter((item, index) => tempList.indexOf(item) === index);
                setRecord({ ...record, documents: uniqueTempDocs });
            }
        }} onKeyDown={e => {
            if (e.key === 'Enter') {
                const tempList = record.documents.slice();
                const tempListLastValid = recordLastValid.documents.slice();
                if (!record.documents[i]) {
                    tempList.splice(i, 1);
                    tempListLastValid.splice(i, 1);
                    setRecord({...record, documents: tempList});
                    setRecordLastValid({...recordLastValid, documents: tempListLastValid});
                } else {
                    tempList[i] = recordLastValid.documents[i];
                    const uniqueTempDocs = tempList.filter((item, index) => tempList.indexOf(item) === index);
                    setRecord({ ...record, documents: uniqueTempDocs });
                    setRecordLastValid({...recordLastValid, documents: uniqueTempDocs});
                }
                document.activeElement.blur();
            }
        }}/>
    );
}

function NewDocument({record, setRecord, recordLastValid, setRecordLastValid, trigger}) {
    const [ doc, setDoc ] = useState('');
    const [ docLastValid, setDocLastValid ] = useState('');

    return (
        <input className="doc-input" value={doc} onChange={str => {
            if ((/^[RBC][1-9]\d{0,1}$/).test(str.target.value)) setDocLastValid(str.target.value);
            setDoc(str.target.value);
        }} onBlur={() => {
            setDoc(docLastValid);
            if (docLastValid) {
                const tempDocs = record.documents.slice();
                const tempDocsLastValid = recordLastValid.documents.slice();
                if (!tempDocs.includes(docLastValid)) { 
                    tempDocs.push(docLastValid);
                    tempDocsLastValid.push(docLastValid);
                    setRecord({...record, documents: tempDocs.slice()});
                    setRecordLastValid({...recordLastValid, documents: tempDocsLastValid.slice()});
                    setDoc('');
                    setDocLastValid('');
                } else { 
                    setDoc(''); 
                    setDocLastValid('');
                }
            }
        }} onKeyDown={e => {
            if (e.key === 'Enter') {
                setDoc(docLastValid);
                if (docLastValid) {
                    const tempDocs = record.documents.slice();
                    const tempDocsLastValid = recordLastValid.documents.slice();
                    if (!tempDocs.includes(docLastValid)) { 
                        tempDocs.push(docLastValid);
                        tempDocsLastValid.push(docLastValid);
                        setRecord({...record, documents: tempDocs.slice()});
                        setRecordLastValid({...recordLastValid, documents: tempDocsLastValid.slice()});
                        setDoc('');
                        setDocLastValid('');
                    } else { 
                        setDoc(''); 
                        setDocLastValid('');
                    }
                }
            }
        }}/>
    );
}

export default function AtomicRecord({id, item, trigger, setDeleteRequested, setNewRecord, isNewRecord, refresh, db, setCanMakeChanges, canMakeChanges}) {
    const [ record, setRecord ] = useState(item);
    const [ recordLastValid, setRecordLastValid ] = useState(item);
    const [ initialRecord, setInitialRecord ] = useState(item);
    const [ largeScreen, setLargeScreen ] = useState(window.matchMedia('(min-device-width: 769px)').matches || window.screen.width > 768);
    const [ hidden, setHidden ] = useState(true);

    useEffect(() => {
        if (isNewRecord) setHidden(false);
    },[]);

    useEffect(() => {
        setLargeScreen(window.matchMedia('(min-device-width: 769px)').matches || window.screen.width > 768);
    });

    var validStartDate = new Date('2023-08-16');
    var validStopDate = new Date('2026-01-01');

    const { instance } = useMsal();

    function handleDateChange(v) {
        const dateInput = new Date(v.target.value);   
        setRecord({ ...record, 
                    day: dateInput.getDate(),
                    month: dateInput.getMonth() + 1,
                    year: dateInput.getFullYear()});
        if (dateInput >= validStartDate && dateInput < validStopDate) 
            setRecordLastValid({...recordLastValid,
                    day: dateInput.getDate(),
                    month: dateInput.getMonth() + 1,
                    year: dateInput.getFullYear()});
    }

    function handleFxDateChange(v) {
        const dateInput = new Date(v.target.value);
        var fxRate = null;
        const urlFx = `https://nbg.gov.ge/gw/api/ct/monetarypolicy/currencies/en/json/?currencies=${record.currency}&date=${v.target.value}`;
        setRecord({ ...record,
                    fx_day: dateInput.getDate(),
                    fx_month: dateInput.getMonth() + 1,
                    fx_year: dateInput.getFullYear()});
        if (dateInput >= validStartDate && dateInput < validStopDate) {
            setRecordLastValid({...recordLastValid,
                    fx_day: dateInput.getDate(),
                    fx_month: dateInput.getMonth() + 1,
                    fx_year: dateInput.getFullYear()});
            fetch(urlFx)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.error(`API request for FX rate for ${record.currency} and ${v.target.value} failed with status: ${response.status}`);
                    }
                })
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        const firstObject = data[0];
                        if (Array.isArray(firstObject.currencies) && firstObject.currencies.length > 0) {
                            fxRate = Math.round(firstObject.currencies[0].rate * 10000);
                            console.log(`The fx rate for ${record.currency} on ${v.target.value} was ${fxRate}`);
                        } else {
                            console.log(`Invalid data structure returned by API request for FX rate for ${record.currency} and ${v.target.value} ("currencies" is not an array or is empty)`);
                        }
                    } else {
                        console.log(`Invalid data structure returned by API request for FX rate for ${record.currency} and ${v.target.value} ("rate" is not an array or is empty)`);
                    }
                })
                .then(() => {
                    setRecord({...record, 
                        fx: fxRate, 
                        fx_day: dateInput.getDate(),
                        fx_month: dateInput.getMonth() + 1,
                        fx_year: dateInput.getFullYear()});
                    if (fxRate) setRecordLastValid({...recordLastValid, 
                        fx: fxRate,
                        fx_day: dateInput.getDate(),
                        fx_month: dateInput.getMonth() + 1,
                        fx_year: dateInput.getFullYear()});
                })
                .catch((error) => {
                    console.error(`An error occurred while fetching FX rate for ${record.currency} and ${v.target.value} at website of National Bank of Georgia: ${error}`);
                });
        }

    }

    function handleStartDateChange(v) {
        const dateInput = new Date(v.target.value);   
        setRecord({ ...record, 
                    day_start: dateInput.getDate(),
                    month_start: dateInput.getMonth() + 1,
                    year_start: dateInput.getFullYear()});
        if (dateInput >= validStartDate && dateInput < validStopDate) 
            setRecordLastValid({...recordLastValid,
                    day_start: dateInput.getDate(),
                    month_start: dateInput.getMonth() + 1,
                    year_start: dateInput.getFullYear()});
    }

    function handleDateDefocus() {
        const currentRecordDate = new Date(`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`);
        if (currentRecordDate >= validStartDate && currentRecordDate < validStopDate) 
            setRecord({...record,
                    day: recordLastValid.day,
                    month: recordLastValid.month,
                    year: recordLastValid.year});
    }

    function handleFxDateDefocus() {
        const currentRecordDate = new Date(`${record.fx_year}-${String(record.fx_month).padStart(2, '0')}-${String(record.fx_day).padStart(2, '0')}`);
        if (currentRecordDate >= validStartDate && currentRecordDate < validStopDate) 
            setRecord({...record,
                    fx_day: recordLastValid.fx_day,
                    fx_month: recordLastValid.fx_month,
                    fx_year: recordLastValid.fx_year});
    }

    function handleStartDateDefocus() {
        const currentRecordDate = new Date(`${record.year_start}-${String(record.month_start).padStart(2, '0')}-${String(record.day_start).padStart(2, '0')}`);
        if (currentRecordDate >= validStartDate && currentRecordDate < validStopDate) 
            setRecord({...record,
                    day_start: recordLastValid.day_start,
                    month_start: recordLastValid.month_start,
                    year_start: recordLastValid.year_start});
    }

    function handleDebitChange(v) {
        const inputDigitsOnly = v.target.value.replace(/\D/g, '');
        if (inputDigitsOnly.length === 4 && parseInt(inputDigitsOnly, 10) in CofA) setRecordLastValid({...recordLastValid, debit: inputDigitsOnly});
        setRecord({...record, debit: inputDigitsOnly});
    }

    function handleCreditChange(v) {
        const inputDigitsOnly = v.target.value.replace(/\D/g, '');
        if (inputDigitsOnly.length === 4 && parseInt(inputDigitsOnly, 10) in CofA) setRecordLastValid({...recordLastValid, credit: inputDigitsOnly});
        setRecord({...record, credit: inputDigitsOnly});
    }

    function handleAssetAccountChange(v) {
        const inputDigitsOnly = v.target.value.replace(/\D/g, '');
        if (inputDigitsOnly.length === 4 && parseInt(inputDigitsOnly, 10) in CofA) setRecordLastValid({...recordLastValid, 'asset account': inputDigitsOnly});
        setRecord({...record, 'asset account': inputDigitsOnly});
    }

    function handleDebitDefocus() {
        setRecord({...record, debit: recordLastValid.debit});
    }

    function handleDebitEnter(e) {
        if (e.key === 'Enter') { 
            setRecord({...record, debit: recordLastValid.debit});
            document.activeElement.blur();
        }
    }

    function handleCreditDefocus() {
        setRecord({...record, credit: recordLastValid.credit});
    }

    function handleCreditEnter(e) {
        if (e.key === 'Enter') { 
            setRecord({...record, credit: recordLastValid.credit});
            document.activeElement.blur();
        }
    }

    function handleAssetAccountDefocus() {
        setRecord({...record, 'asset account': recordLastValid['asset account']});
    }

    function handleAssetAccountEnter(e) {
        if (e.key === 'Enter') {
            setRecord({...record,  'asset account': recordLastValid['asset account']});
            document.activeElement.blur();
        }
    }

    function handleAmountChange(v) {
        const floatAmount = parseFloat(v.target.value);
        setRecord({...record, amount: Math.round(floatAmount * 100)});
        if (floatAmount >= 0.01 && floatAmount < 10000.0) setRecordLastValid({...recordLastValid, amount: Math.round(floatAmount * 100)});
    }

    function handleAmountDefocus() {
        setRecord({...record, amount: recordLastValid.amount});
    }

    function handleAmountEnter(e) {
        if (e.key === 'Enter') { 
            setRecord({...record, amount: recordLastValid.amount});
            document.activeElement.blur();
        }
    }

    function handleFxChange(v) {
        const tempValue = Math.round(v.target.value * 10000);
        setRecord({...record, fx: tempValue});
        setRecordLastValid({...recordLastValid, fx: tempValue});
    }

    function handleFxDefocus() {
        setRecord({...record, fx: recordLastValid.fx});
    }

    function handleFxEnter(e) {
        if (e.key === 'Enter') { 
            setRecord({...record, fx: recordLastValid.fx});
            document.activeElement.blur();
        }
    }

    function handleCurrencyChange(v) {
        if (/^[DEGLRSU]{0,3}$/.test(v.target.value))
            setRecord({...record, currency: v.target.value });
        if (v.target.value === 'GEL') {
            const temptRecord = recordLastValid;
            delete temptRecord['fx'];
            delete temptRecord['fx_day'];
            delete temptRecord['fx_month'];
            delete temptRecord['fx_year'];
            setRecordLastValid({...temptRecord, currency: v.target.value });
        } else if (['USD', 'EUR'].includes(v.target.value)) {
            setRecordLastValid({...recordLastValid, currency: v.target.value });
            var fxRate = null;
            const fxDate =  record.fx_year ? 
                `${record.fx_year}-${String(record.fx_month).padStart(2, '0')}-${String(record.fx_day).padStart(2, '0')}` :
                `${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`;
            const urlFx = `https://nbg.gov.ge/gw/api/ct/monetarypolicy/currencies/en/json/?currencies=${v.target.value}&date=${fxDate}`;
            fetch(urlFx)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.error(`API request for FX rate for ${v.target.value} and ${v.target.value} failed with status: ${response.status}`);
                    }
                })
                .then((data) => {
                    if (Array.isArray(data) && data.length > 0) {
                        const firstObject = data[0];
                        if (Array.isArray(firstObject.currencies) && firstObject.currencies.length > 0) {
                            fxRate = Math.round(firstObject.currencies[0].rate * 10000);
                            console.log(`The fx rate for ${v.target.value} on ${fxDate} was ${fxRate}`);
                        } else {
                            console.log(`Invalid data structure returned by API request for FX rate for ${v.target.value} and ${fxDate} ("currencies" is not an array or is empty)`);
                        }
                    } else {
                        console.log(`Invalid data structure returned by API request for FX rate for ${v.target.value} and ${fxDate} ("rate" is not an array or is empty)`);
                    }
                })
                .then(() => {
                    setRecord({...record, 
                        fx: fxRate, 
                        currency: v.target.value});
                    if (fxRate) setRecordLastValid({...recordLastValid, 
                        fx: fxRate,
                        currency: v.target.value});
                })
                .catch((error) => {
                    console.error(`An error occurred while fetching FX rate for ${record.currency} and ${v.target.value} at website of National Bank of Georgia: ${error}`);
                });
        }
            
    }

    function handleCurrencyDefocus() {
        setRecord({...record, currency: recordLastValid.currency});
    }

    function handleCurrencyEnter(e) {
        if (e.key === 'Enter') {
            setRecord({...record, currency: recordLastValid.currency});
            document.activeElement.blur();
        }
    }

    function compareArrays(arrA, arrB) {
        if (arrA.length !== arrB.length) return false;
        const sortedArrA = arrA.slice().sort();
        const sortedArrB = arrB.slice().sort();
        return sortedArrA.every((value, index) => value === sortedArrB[index]);
    }

    function areRecordsEqual(recordNew, recordInitial) {
        //const keysInitial = Object.keys(recordInitial);
        const keysNew = Object.keys(recordNew);
      
        return keysNew.every(key => {
            if (recordInitial.hasOwnProperty(key)) {
                if (key==='documents') return compareArrays(recordNew.documents, recordInitial.documents);
                else return recordNew[key] === recordInitial[key];
            }
            else return (recordNew[key] === false || recordNew[key] === '') ;
        });
    }

    function saveToDataBase() {
        putCosmosDBRecord({ setCanMakeChanges: setCanMakeChanges, instance: instance, record: recordLastValid, initial: initialRecord, setInitialRecord: setInitialRecord, method: 'PUT' });
    }

    async function addToDataBase() {
        await putCosmosDBRecord({ setCanMakeChanges: setCanMakeChanges, instance: instance, record: recordLastValid, setInitialRecord: setInitialRecord, method: 'POST' });
        setNewRecord(null);
        const elementToScroll = document.documentElement || document.body;
        elementToScroll.scrollTop = elementToScroll.scrollHeight;
        setTimeout(function () {
            refresh();
          }, 500);
    }

    useEffect(() => { 
        setRecord({...initialRecord, documents: initialRecord.documents.slice() });
        setRecordLastValid({...initialRecord, documents: initialRecord.documents.slice() });
    }, [trigger]);

    const [ isDragging, setDragging ] = useState(false);
    const [ offset, setOffset ] = useState({ x: -205, y: 0 });
    const [ initialPosition, setInitialPosition ] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        if (e.target === e.currentTarget) {
            setDragging(true);
            setInitialPosition({
                x: e.clientX,
                y: e.clientY,
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
        requestAnimationFrame(() => {
            e.preventDefault(); // Prevent text selection during drag
            setOffset({x: offset.x + e.clientX - initialPosition.x,
                y: offset.y + e.clientY - initialPosition.y
            });
            // Update the position of the draggable element
            //e.target.style.transform = `translate(${x}px, ${y}px)`;
        });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            document.addEventListener('touchmove', handleMouseMove);
            document.addEventListener('touchend', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging]);


    function resetButtonClick() {
        setRecord({...initialRecord, documents: initialRecord.documents.slice() });
        setRecordLastValid({...initialRecord, documents: initialRecord.documents.slice() });
    }

    function changeNote(v) { 
        setRecord({...record, note: v.target.value});
        if (v.target.value) {
            setRecordLastValid({...recordLastValid, note: v.target.value});
        } else {
            const tempRecordLastValid = recordLastValid;
            delete tempRecordLastValid.note;
            setRecordLastValid(tempRecordLastValid);
        }
    }

    function changeDescription(v) {
        setRecord({...record, description: v.target.value});
        setRecordLastValid({...recordLastValid, description: v.target.value});
    }

    function switchDelayed (v) {
        const tempRecord = record;
        const tempRecordLastValid = recordLastValid;
        if (!v.target.checked) {
            delete tempRecord.year_start;
            delete tempRecord.month_start;
            delete tempRecord.day_start;
            delete tempRecordLastValid.year_start;
            delete tempRecordLastValid.month_start;
            delete tempRecordLastValid.day_start;
            setRecord({...tempRecord, delayed: v.target.checked});
            setRecordLastValid({...tempRecordLastValid, delayed: v.target.checked});
        } else {
            setRecord({...tempRecord, delayed: v.target.checked, year_start: record.year, month_start: record.month, day_start: record.day});
            setRecordLastValid({...tempRecordLastValid, delayed: v.target.checked, year_start: recordLastValid.year, month_start: recordLastValid.month, day_start: recordLastValid.day});
        }
    }

    function switchDelayedSmall () {
        const tempRecord = record;
        const tempRecordLastValid = recordLastValid;
        if (record.delayed) {
            delete tempRecord.year_start;
            delete tempRecord.month_start;
            delete tempRecord.day_start;
            delete tempRecordLastValid.year_start;
            delete tempRecordLastValid.month_start;
            delete tempRecordLastValid.day_start;
            setRecord({...tempRecord, delayed: !record.delayed});
            setRecordLastValid({...tempRecordLastValid, delayed: !record.delayed});
        } else {
            setRecord({...tempRecord, delayed: !record.delayed, year_start: record.year, month_start: record.month, day_start: record.day});
            setRecordLastValid({...tempRecordLastValid, delayed: !record.delayed, year_start: recordLastValid.year, month_start: recordLastValid.month, day_start: recordLastValid.day});
        }
    }

    function changeSchedule(v) {
        const intValue = Math.round(v.target.value);
        setRecord({...record, schedule: intValue});
        if (intValue > 0 && intValue < 60) setRecordLastValid({...recordLastValid, schedule: intValue});
    }

    function blurSchedule() {
            setRecord({...record, schedule: recordLastValid.schedule});
    }

    function keyDownSchedule (e) {
        if (e.key === 'Enter') {
            setRecord({...record, schedule: recordLastValid.schedule});
            document.activeElement.blur();
        }
    }

    function switchAmortization (v) {
        const tempRecord = record;
        const tempRecordLastValid = recordLastValid;
        if (!v.target.checked) {
            delete tempRecord['asset account'];
            delete tempRecord.schedule;
            delete tempRecordLastValid['asset account'];
            delete tempRecordLastValid.schedule;
            setRecord({...tempRecord, amortization: v.target.checked});
            setRecordLastValid({...tempRecordLastValid, amortization: v.target.checked});
        } else {
            setRecord({...record, amortization: v.target.checked, 'asset account': "1710", schedule: 12 });
            setRecordLastValid({...recordLastValid, amortization: v.target.checked, 'asset account': "1710", schedule: 12});
        }
    }

    function switchAmortizatioSmall () {
        const tempRecord = record;
        const tempRecordLastValid = recordLastValid;
        if (record.amortization) {
            delete tempRecord['asset account'];
            delete tempRecord.schedule;
            delete tempRecordLastValid['asset account'];
            delete tempRecordLastValid.schedule;
            setRecord({...tempRecord, amortization: !record.amortization});
            setRecordLastValid({...tempRecordLastValid, amortization: !recordLastValid.amortization});
        } else {
            setRecord({...record, amortization: !record.amortization, 'asset account': "1710", schedule: 12 });
            setRecordLastValid({...recordLastValid, amortization: !recordLastValid.amortization, 'asset account': "1710", schedule: 12});
        }
    }


    if (record && largeScreen) return (
        <div
        className={`atomic-record ${ isNewRecord ? "new-atomic-record" : ""} ${isDragging ? 'dragging' : ''}`}
        style={ isNewRecord ? { transform: `translate(${offset.x}px, ${offset.y}px)`} : isDragging? { zIndex: 9999 } : null}
        onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}  >
            <div style={{width: "100%"}}>
                {isNewRecord && <h4 className="new-record-title">New record</h4>}
                <form id={trigger}>
                    <label>Record id&nbsp;</label> {record.id}<br/>
                    <label> Date&nbsp;
                        <input type="date" min="2023-08-16" max="2025-12-31" className="date" name="date" onChange={handleDateChange} onBlur={handleDateDefocus} value={`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`} />
                    </label><br/>
                    <label htmlFor="debit"> Dr&nbsp;</label>
                    <span className="tooltip-cell">
                        <input type="text" className="debit" id="debit" name="debit" value={record.debit} onChange={handleDebitChange} onBlur={handleDebitDefocus} onKeyDown={handleDebitEnter}/>
                        <span className="tooltip-text tooltip-debit">{CofA[record.debit]}</span>
                    </span>
                    <label htmlFor="credit">&nbsp;&nbsp;Cr&nbsp;</label>
                    <span className="tooltip-cell">
                        <input type="text" className="credit" id="credit" name="credit" value={record.credit} onChange={handleCreditChange} onBlur={handleCreditDefocus} onKeyDown={handleCreditEnter}/>
                        <span className="tooltip-text tooltip-credit">{CofA[record.credit]}</span>
                    </span>
                    { record.amortization && <><label htmlFor="asset-account"> Asset account </label>
                    <span className="tooltip-cell">
                        <input type="text" className="asset-account" name="asset-account" placeholder="1710" value={record['asset account']} onChange={handleAssetAccountChange} onBlur={handleAssetAccountDefocus} onKeyDown={handleAssetAccountEnter}/>
                        <span className="tooltip-text tooltip-asset-account">{CofA[record['asset account']]}</span>
                    </span></> }<br/>
                    <label htmlFor="amount"> Amount&nbsp;
                        <input type="number" min="0.01" step="0.01" className="amount" id="amount" name="amount" value={record.amount/100.0} onChange={handleAmountChange} onBlur={handleAmountDefocus} onKeyDown={handleAmountEnter}/>
                        <input type="text" className="currency" name="currency" value={record.currency} onChange={handleCurrencyChange} onBlur={handleCurrencyDefocus} onKeyDown={handleCurrencyEnter}/>
                    </label><br/>
                    <label> VAT included&nbsp;
                    <input type="checkbox" className="VATincluded" name="VATincluded" checked={record['VAT included']} onChange={v => {
                        setRecord({...record, 'VAT included': v.target.checked}); 
                        setRecordLastValid({...recordLastValid, 'VAT included': v.target.checked});
                        }} disabled={record['reverse VAT']}/></label>
                    <label> Reverse VAT&nbsp;
                    <input type="checkbox" className="reverseVAT" name="reverseVAT" checked={record['reverse VAT']} onChange={v => {
                        setRecord({...record, 'reverse VAT': v.target.checked});
                        setRecordLastValid({...recordLastValid, 'reverse VAT': v.target.checked});
                        }} disabled={record['VAT included']}/></label><br/>
                    {(record.currency !== 'GEL') && <><label htmlFor="fx">{record.currency}/GEL exchange rate&nbsp;
                        <input type="number" min="0.0001" step="0.0001" className="fx" id="fx" name="fx" value={record.fx/10000.0} onChange={handleFxChange} onBlur={handleFxDefocus} onKeyDown={handleFxEnter}/><br/>
                    </label>
                    <label> Exchange rate date&nbsp;
                        {record.fx_year ?
                        <input type="date" min="2023-08-16" max="2025-12-31"className="fx_date" name="fx_date" onChange={handleFxDateChange} onBlur={handleFxDateDefocus} value={`${record.fx_year}-${String(record.fx_month).padStart(2, '0')}-${String(record.fx_day).padStart(2, '0')}`} />
                        : <input type="date" min="2023-08-16" max="2025-12-31"className="fx_date" name="fx_date" onChange={handleFxDateChange} onBlur={handleFxDateDefocus} value={`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`} />}
                    </label><br/></>}
                    
                    <label> Amortization&nbsp;
                    <input type="checkbox" className="amortization" name="amortization" checked={record.amortization} onChange={switchAmortization}/></label><br/>
                    { record.amortization && <><label> Months of amortization&nbsp;
                    <input type="number" min="1" step="1" max="60" className="schedule" name="schedule" placeholder="12" value={record.schedule} onChange={changeSchedule} onBlur={blurSchedule} onKeyDown={keyDownSchedule}/></label><br/></> }
                    <label> Delayed&nbsp;
                    <input type="checkbox" className="delayed" checked={record.delayed} onChange={switchDelayed} /><br/></label>
                    { record.delayed && <label> Delayed date&nbsp;
                        <input type="date" min="2023-08-16" max="2025-12-31" className="date_start" name="date_start" onChange={handleStartDateChange} onBlur={handleStartDateDefocus} value={`${record.year_start}-${String(record.month_start).padStart(2, '0')}-${String(record.day_start).padStart(2, '0')}`} />
                        <br/></label> }
                    <label> Description&nbsp;<br/>
                    <textarea className="description regular" rows="3" value={record.description != null ? record.description : ''} onChange={changeDescription}/></label><br/>
                    <label> Note&nbsp;<br/>
                    <textarea className="note regular" rows="3" value={record.note != null ? record.note : '' } onChange={changeNote}/></label><br/>
                    <label htmlFor="documents">Documents&nbsp;</label>
                    { recordLastValid.documents.map((doc, i) => (
                        <Document i={i} docProp={doc} record={record} setRecord={setRecord} recordLastValid={recordLastValid} setRecordLastValid={setRecordLastValid} initialRecord={initialRecord} trigger={trigger}/>)) }
                    <NewDocument record={record} setRecord={setRecord} recordLastValid={recordLastValid} setRecordLastValid={setRecordLastValid} initialRecord={initialRecord} trigger={trigger}/>
                </form>
                <div className="atomic-record-button-container">
                    <button className="atomic-record-button" onClick={resetButtonClick} disabled={areRecordsEqual(record, initialRecord)}>Reset</button>
                    <button className="atomic-record-button" onClick={isNewRecord ? addToDataBase : saveToDataBase} disabled={isNewRecord ? false : areRecordsEqual(record, initialRecord)}>Save</button>
                    {!isNewRecord && <button className="atomic-record-button" onClick={async () => {
                        await refresh(); 
                        setNewRecord({...recordLastValid, documents: recordLastValid.documents.slice() });
                    }} >New</button>}
                    {isNewRecord && <button className="atomic-record-button" onClick={() => setNewRecord(null)} >Cancel</button>}
                    {/*!isNewRecord && <button className="atomic-record-button" onClick={() => requestMakeChanges({initialRecord: initialRecord, proposedChange: recordLastValid, instance: instance})} >Approve</button>*/}
                    {!isNewRecord && <button className="atomic-record-button" onClick={() => setDeleteRequested({ recordLastValid: recordLastValid, setInitialRecord: setInitialRecord })} >Delete</button>}
                </div>
           </div>
        </div>
    );
    else if (record && !hidden) return (<div
        className={`atomic-record-small ${ isNewRecord ? "new-atomic-record-small" : ""}`}
        onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}  >
                <div onClick={() => setHidden(true)}><BsArrowBarUp/></div>
                {isNewRecord && <h4 className="new-record-title">New record</h4>}
                <form id={trigger}>
                    <label className="small-left">Record id</label>
                                    <div className="small-right">{record.id}</div>
                    <label className="small-left"> Date</label>
                        <div className="small-right-div">
                            <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="date" onChange={handleDateChange} onBlur={handleDateDefocus} value={`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`} />
                        </div>
                        
                    <label className="small-left" htmlFor="debit">Dr</label>
                    <span className="tooltip-cell small-right-div">
                        <input type="text" className="debit small" id="debit" name="debit" value={record.debit} onChange={handleDebitChange} onBlur={handleDebitDefocus} onKeyDown={handleDebitEnter}/>
                        <span className="tooltip-text tooltip-debit-small">{CofA[record.debit]}</span>
                    </span>
                    <label className="small-left" htmlFor="credit">Cr</label>
                    <span className="tooltip-cell small-right-div">
                        <input type="text" className="credit small" id="credit" name="credit" value={record.credit} onChange={handleCreditChange} onBlur={handleCreditDefocus} onKeyDown={handleCreditEnter}/>
                        <span className="tooltip-text tooltip-credit-small">{CofA[record.credit]}</span>
                    </span>
                    <label className="small-left" htmlFor="amount"> Amount</label>
                        <div className="small-right-div">
                            <input type="number" min="0.01" step="0.01" className="amount small" id="amount" name="amount" value={record.amount/100.0} onChange={handleAmountChange} onBlur={handleAmountDefocus} onKeyDown={handleAmountEnter}/>
                            <input type="text" className="currency small" name="currency" value={record.currency} onChange={handleCurrencyChange} onBlur={handleCurrencyDefocus} onKeyDown={handleCurrencyEnter}/>
                        </div>
                       
                    
                    {(record.currency !== 'GEL') && <><label className="small-left" htmlFor="fx">{record.currency}/GEL rate</label>
                        <div className="small-right-div">
                            <input type="number" min="0.0001" step="0.0001" className="fx small" id="fx" name="fx" value={record.fx/10000.0} onChange={handleFxChange} onBlur={handleFxDefocus} onKeyDown={handleFxEnter}/>
                        </div>
                    
                    <label className="small-left"> FX rate date</label>
                        <div className="small-right-div">
                            {record.fx_year ?
                            <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="fx_date" onChange={handleFxDateChange} onBlur={handleFxDateDefocus} value={`${record.fx_year}-${String(record.fx_month).padStart(2, '0')}-${String(record.fx_day).padStart(2, '0')}`} />
                            : <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="fx_date" onChange={handleFxDateChange} onBlur={handleFxDateDefocus} value={`${record.year}-${String(record.month).padStart(2, '0')}-${String(record.day).padStart(2, '0')}`} />}
                        </div>
                    </>}
                    <label className="small-left">VAT included&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <div className={record['reverse VAT'] ? "switch-non-active": "switch-active"} onClick={() => {
                            if (!record['reverse VAT']) {
                                setRecord({...record, 'VAT included': !record['VAT included']}); 
                                setRecordLastValid({...recordLastValid, 'VAT included': !record['VAT included']});
                            }
                        }}>{record['VAT included'] ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    
                    <label className="small-left"> Reverse VAT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <div className={record['VAT included'] ? "switch-non-active": "switch-active"} onClick={() => {
                            if (!record['VAT included']) {
                                setRecord({...record, 'reverse VAT': !record['reverse VAT']}); 
                                setRecordLastValid({...recordLastValid, 'reverse VAT': !record['reverse VAT']});
                            }
                        }}>{record['reverse VAT'] ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    
                    <label className="small-left"> Amortization&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <div className="switch-active" onClick={switchAmortizatioSmall}>{record.amortization ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    
                    { record.amortization && <><label className="small-right-div" htmlFor="asset-account">asset account</label>
                    <span className="tooltip-cell small-left">
                        <input type="text" className="asset-account small" name="asset-account" placeholder="1710" value={record['asset account']} onChange={handleAssetAccountChange} onBlur={handleAssetAccountDefocus} onKeyDown={handleAssetAccountEnter}/>
                        <span className="tooltip-text tooltip-asset-account-small">{CofA[record['asset account']]}</span>
                    </span>
                    <div className="small-right-div">
                        <input type="number" min="1" step="1" max="60" className="schedule small" name="schedule" placeholder="12" value={record.schedule} onChange={changeSchedule} onBlur={blurSchedule} onKeyDown={keyDownSchedule}/>
                    </div>
                    <label className="small-left">&nbsp;months</label></> }
                    <label> Delayed&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <div className="switch-active" onClick={switchDelayedSmall}>{record.delayed ? <BsToggleOn className="toggles"/> : <BsToggleOff className="toggles"/>}</div>
                    { record.delayed && <>
                        <div className="small-right-div">
                            <label>until&nbsp;</label>
                        </div>
                        <div className="small-left-div">
                            <input type="date" min="2023-08-16" max="2025-12-31" className="small-right small" name="date_start" onChange={handleStartDateChange} onBlur={handleStartDateDefocus} value={`${record.year_start}-${String(record.month_start).padStart(2, '0')}-${String(record.day_start).padStart(2, '0')}`} />
                        </div>
                    </>}
                    <div className="span-two-columns">
                        <label className="small-left description small"> Description<br/>
                        <textarea className="description small" rows="3" value={record.description != null ? record.description : ''} onChange={changeDescription}/></label>
                    </div>
                    <div className="span-two-columns">
                        <label className="small-left note small"> Note&nbsp;
                        <textarea className="note small" rows="3" value={record.note != null ? record.note : '' } onChange={changeNote}/></label>
                    </div>
                    <div className="span-two-columns" style={{display: 'flex', flexWrap: 'wrap'}}>
                        <label className="small-left" htmlFor="documents">Documents&nbsp;</label>
                        { recordLastValid.documents.map((doc, i) => (
                            <Document i={i} docProp={doc} record={record} setRecord={setRecord} recordLastValid={recordLastValid} setRecordLastValid={setRecordLastValid} initialRecord={initialRecord} trigger={trigger}/>)) }
                            <NewDocument record={record} setRecord={setRecord} recordLastValid={recordLastValid} setRecordLastValid={setRecordLastValid} initialRecord={initialRecord} trigger={trigger}/>
                    </div>
                </form>
                <div className="atomic-record-button-container">
                    <button className="atomic-record-button" onClick={resetButtonClick} disabled={areRecordsEqual(record, initialRecord)}>Reset</button>
                    <button className="atomic-record-button" onClick={isNewRecord ? addToDataBase : saveToDataBase} disabled={isNewRecord ? false : areRecordsEqual(record, initialRecord)}>Save</button>
                    {!isNewRecord && <button className="atomic-record-button" onClick={async () => {
                        setHidden(true);
                        await refresh();
                        setNewRecord({...recordLastValid, documents: recordLastValid.documents.slice() });
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                          });
                    }} >New</button>}
                    {isNewRecord && <button className="atomic-record-button" onClick={() => setNewRecord(null)} >Cancel</button>}
                    {!isNewRecord && <button className="atomic-record-button" onClick={() => setDeleteRequested({ recordLastValid: recordLastValid, setInitialRecord: setInitialRecord })} >Delete</button>}
                </div>
        </div>);
        else if (record && hidden) return (<div
            className={`atomic-record-small-hidden ${ isNewRecord ? "new-atomic-record-small-hidden" : ""}`}
            onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}  >
                    {isNewRecord && <h4 className="new-record-title">Unsaved record</h4>}
                    <p className="date-hidden">{String(record.day).padStart(2, '0')}.{String(record.month).padStart(2, '0')}.{record.year}</p>
                    <p className="debit-credit-hidden">
                        Dr&nbsp;&nbsp;
                        <span className="tooltip-cell">
                        {record.debit}<span className="tooltip-text tooltip-debit-small-hidden">{CofA[record.debit]}</span>
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cr&nbsp;&nbsp;
                        <span className="tooltip-cell">
                            {record.credit}<span className="tooltip-text tooltip-credit-small-hidden">{CofA[record.credit]}</span>
                        </span>
                    </p>
                    <p className="amount-hidden">{record.amount/100.0} {record.currency}</p>                   
                    <p className="descritopn-hidden">{record.description != null ? record.description : ''}</p>
                    <div onClick={() => setHidden(false)}><BsArrowBarDown/></div>
            </div>);
}