import exitImage from './exit.png';
import refreshImage from './refresh.png';
import backImage from './back.png';
import { useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Link, NavLink } from "react-router-dom";
import { getCosmosDBData } from "./fetchData";
import AtomicRecord from "./AtomicRecord";
import './AtomicRecords.css';
import { startOfMonth, endOfMonth, addMonths, subMonths, format } from 'date-fns';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import CantDoWarning from './CantDoWarning';
import DeleteWarning from './DeleteWarning';

export default function AtomicRecords(props) {
    const [ largeScreen, setLargeScreen ] = useState(window.matchMedia('(min-device-width: 769px)').matches || window.screen.width > 768);
    const [startX, setStartX] = useState(null);

    const handleTouchStart = (event) => {
        setStartX(event.touches[0].clientX);
    };

    const handleTouchMove = (event) => {
        if (!startX) return;

        const currentX = event.touches[0].clientX;
        const deltaX = currentX - startX;

        if (deltaX > 150 && startDate >= '2023-09-01') {
            periodBack();
        } else if (deltaX < -150 && endDate <= '2025-11-30') {
            periodForth();
        }
    };

    const handleTouchEnd = () => {
        setStartX(null);
    };

    useEffect(() => props.setToDeleteHeaderFooter(true),[]);

    useEffect(() => {
        setLargeScreen(window.matchMedia('(min-device-width: 769px)').matches || window.screen.width > 768);
    });

    const { instance } = useMsal();
    const [ trigger, setTrigger ] = useState(Math.random());

    const [ newRecord, setNewRecord ] = useState(false);

    async function refresh() {
        await getCosmosDBData({ instance: instance, setDataCosmosDB: props.setDataCosmosDB });
        setTrigger(Math.random());
    }

    useEffect(() => {
        setTrigger(Math.random());
    }, [props.dataCosmosDB]);

    const [ startDate, setStartDate ] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    const [ endDate, setEndDate ] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'));

    function returnNewID(records) {
        const idArray = records.map(obj => parseInt(obj.id, 10));
        idArray.sort((a, b) => a - b);
        let lowestPositive = 1;
        for (let i = 0; i < idArray.length; i++) {
            if (idArray[i] === lowestPositive) {
                lowestPositive++;
            } else if (idArray[i] > lowestPositive) {
                return String(lowestPositive).padStart(7, '0');
            }
        }
        return String(lowestPositive).padStart(7, '0');
    }

    function periodBack() {
        setCurrentSwipeBack(0);
        setCurrentSwipeBack(2);
    }

    function periodForth() {
        setCurrentSwipeForth(0);
        setCurrentSwipeForth(2);
    }

    const [ canMakeChanges, setCanMakeChanges ] = useState(null);
    const [ deleteRequested, setDeleteRequested ] = useState({ recordLastValid: null, setInitialRecord: null });

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const [currentSwipeBack, setCurrentSwipeBack] = useState(0);
    const [currentSwipeForth, setCurrentSwipeForth] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentSwipeBack === 1) {
                setCurrentSwipeBack(2);
            } else if (currentSwipeBack === 2) {
                setCurrentSwipeBack(3);
            } else if (currentSwipeBack === 3) {
                setStartDate(format(startOfMonth(subMonths(new Date(startDate), 1)), 'yyyy-MM-dd'));
                setEndDate(format(endOfMonth(subMonths(new Date(startDate), 1)), 'yyyy-MM-dd'));
                setCurrentSwipeBack(4);
            } else if (currentSwipeBack === 4) {
                setCurrentSwipeBack(0);
            }
        }, 200);

        return () => clearTimeout(timer);
    }, [currentSwipeBack]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentSwipeForth === 2) {
                setCurrentSwipeForth(3);
            } else if (currentSwipeForth === 3) {
                setStartDate(format(startOfMonth(addMonths(new Date(endDate), 1)), 'yyyy-MM-dd'));
                setEndDate(format(endOfMonth(addMonths(new Date(endDate), 1)), 'yyyy-MM-dd'));
                setCurrentSwipeForth(4);
            } else if (currentSwipeForth === 4) {
                setCurrentSwipeForth(0);
            }
        }, 200);

        return () => clearTimeout(timer);
    }, [currentSwipeForth]);

    return (
        <>
            <AuthenticatedTemplate>
                <div className={`swipe-div swipedBack${currentSwipeBack} swipedForth${currentSwipeForth}`} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                    {largeScreen ?
                        <div className="atomic-buttons">
                            <div className="filter-container">
                                <button className="arrow-button" type="button" onClick={periodBack} disabled={startDate < '2023-09-01'}><FiChevronLeft className="arrow"/></button>
                                <input type="date" min="2023-08-16" max="2025-12-31" className="filter" name="date" onChange={v => { if (v.target.value <= endDate) setStartDate(v.target.value);}} value={startDate} />
                                <span className="filter-label">–</span>
                                <input type="date" min="2023-08-16" max="2025-12-31" className="filter" name="date" onChange={v => { if (v.target.value >= startDate) setEndDate(v.target.value);}} value={endDate} />
                                <button className="arrow-button" type="button" onClick={periodForth} disabled={endDate > '2025-11-30'}><FiChevronRight className="arrow"/></button>
                            </div>
                            <NavLink to="/"><button className="exit"><img className="exitImg" src={backImage} alt="Back button" border="0" width="38" height="38"/></button></NavLink>
                            <NavLink to="/atomicrecords"><button className="exit" onClick={refresh}><img className="exitImg" src={refreshImage} alt="Refresh button" border="0" width="38" height="38"/></button></NavLink>
                            <NavLink to="/login"><button className="exit" onClick={props.signOut}><img className="exitImg" src={exitImage} alt="Exit button" border="0" width="38" height="38"/></button></NavLink>
                        </div> : <>
                            <div className="upper-tabs">
                                <NavLink to="/"><button className="exit"><img className="exitImg" src={backImage} alt="Back" border="0" width="38" height="38"/></button></NavLink>
                                <NavLink to="/atomicrecords"><button className="exit" onClick={refresh}><img className="exitImg" src={refreshImage} alt="Refresh" border="0" width="38" height="38"/></button></NavLink>
                                <NavLink to="/login"><button className="exit" onClick={props.signOut}><img className="exitImg" src={exitImage} alt="Exit" border="0" width="38" height="38"/></button></NavLink>
                            </div>
                            <div className="filter-container-small">
                                <button className="arrow-button" type="button" onClick={periodBack} disabled={startDate < '2023-09-01'}><FiChevronLeft className="arrow"/></button>
                                <span>
                                    &nbsp;{new Date(startDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}&nbsp;
                                </span> 
                                <button className="arrow-button" type="button" onClick={periodForth} disabled={endDate > '2025-11-30'}><FiChevronRight className="arrow"/></button>
                            </div>
                        </>
                        }
                        {newRecord && <div>
                            <AtomicRecord key={returnNewID(props.dataCosmosDB)} setCanMakeChanges={setCanMakeChanges} isNewRecord={true} refresh={refresh} item={{...newRecord, id: returnNewID(props.dataCosmosDB)}} db={props.dataCosmosDB} trigger={trigger} setNewRecord={setNewRecord} />
                        </div>}
                        <div className={largeScreen ? "atomic-records" : "atomic-records-small"}>
                            {props.dataCosmosDB.map(item => {
                                const recordDate = `${item.year}-${String(item.month).padStart(2, '0')}-${String(item.day).padStart(2, '0')}`;
                                if (recordDate >= startDate && recordDate <= endDate)
                                    return  <AtomicRecord key={item.id} id={item.id} setDeleteRequested={setDeleteRequested} newRecord={false} isNewRecord={false} setCanMakeChanges={setCanMakeChanges} canMakeChanges={canMakeChanges} refresh={refresh} item={item} db={props.dataCosmosDB} trigger={trigger} setNewRecord={setNewRecord}/>;
                                else return null;
                            })}
                        </div>
                        {canMakeChanges && <CantDoWarning largeScreen={largeScreen} canMakeChanges={canMakeChanges} setCanMakeChanges={setCanMakeChanges}/>}
                        {deleteRequested.recordLastValid && <DeleteWarning largeScreen={largeScreen} deleteRequested={deleteRequested} setDeleteRequested={setDeleteRequested} setCanMakeChanges={setCanMakeChanges} db={props.dataCosmosDB} setDataCosmosDB={props.setDataCosmosDB} setTrigger={setTrigger}/>
                    }
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h2 className="no-authorization">You are not authorized to be on this page<br/><br/>Please log in</h2>
                <Link to="/login">
                    <button className="loginButton">
                        <em>L</em>og-in page
                    </button>
                </Link>
            </UnauthenticatedTemplate>
        </>
    );
  }